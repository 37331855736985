<template>
        <div class="mx-auto my-24 max-w-7xl px-6 sm:my-48 lg:px-8">
            <div class="mx-auto max-w-2xl lg:text-center">
                <h2 class="text-base font-semibold leading-7 text-red-600">{{ $t('services') }}</h2>
                <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{{ $t('services.title') }}</p>
                <p class="mt-6 text-lg leading-8 text-gray-600">{{ $t('services.subtitle') }}</p>
            </div>
            <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                    <NuxtLink v-for="(feature, index) in features" :key="feature.name" :to="localePath(feature.href)" class="relative pl-16" data-aos="zoom-in-up" data-aos-delay="100">
                        <dt class="text-base font-semibold leading-7 text-gray-900">
                            <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-red-600 hover:scale-125 cursor-pointer transition ease-in-out duration-300">
                                <component :is="feature.icon" class="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            {{ feature.name }}
                        </dt>
                        <dd class="mt-2 text-base leading-7 text-gray-600">{{ feature.description }}</dd>
                    </NuxtLink>
                </dl>
                <div class="flex justify-center items-center mt-16">
                    <NuxtLink :to="localePath('/blockchain')" class="relative pl-16 sm:w-1/2" data-aos="zoom-in-up" data-aos-delay="100">
                        <dt class="text-base font-semibold leading-7 text-gray-900">
                            <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-red-600 hover:scale-125 cursor-pointer transition ease-in-out duration-300">
                                <component :is="CubeIcon" class="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            {{ $t('services.blockchain') }}
                        </dt>
                        <dd class="mt-2 text-base leading-7 text-gray-600">{{ $t('services.blockchain.description') }}</dd>
                    </NuxtLink>
                </div>
            </div>
        </div>
</template>
<script setup>
import {
    CloudIcon, CubeIcon,
    HomeModernIcon,
    LockClosedIcon,
    ShieldCheckIcon,
} from '@heroicons/vue/24/outline'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const features = [
    {
        name: t('services.cyber-security'),
        description: t('services.cyber-security.description'),
        href: '/cybersecurity',
        icon: ShieldCheckIcon,
    },
    {
        name: t('services.security'),
        description: t('services.security.description'),
        href: '/security',
        icon: LockClosedIcon,
    },
    {
        name: t('services.smart-home'),
        description: t('services.smart-home.description'),
        href: '/smart-home',
        icon: HomeModernIcon,
    },
    {
        name: t('services.cloud'),
        description: t('services.cloud.description'),
        href: '/cloud',
        icon: CloudIcon,
    },
    // {
    //     name: t('services.blockchain'),
    //     description: t('services.blockchain.description'),
    //     href: '/blockchain',
    //     icon: CubeIcon
    // },
]
</script>
